<template>
  <li class="dropdown user user-menu no-select" v-if="can">
    <a
      href="#"
      class="dropdown-toggle"
      data-toggle="dropdown"
      data-testid="profile-menu"
    >
      <i v-if="busy" class="fa fa-refresh fa-spin"></i>
      <div v-else>
        <span class="hidden-xs">{{ userEmail }}</span>
        <ContractAlertIcon visibility="profile" class="contract-alert-icon" />
        <img
          v-bind:src="userPicture"
          class="user-image user-image-right"
          v-bind:alt="userEmail"
        />
      </div>
    </a>
    <ul class="dropdown-menu">
      <li class="user-header">
        <img
          v-bind:src="userPicture"
          class="img-circle profile_picture"
          alt="User Image"
        />
        <p>
          {{ userFullName }}
          <small>{{ userEmail }}</small>
        </p>
      </li>
      <li class="user-body plan_access" v-on:click.stop.prevent>
        <div class="row">
          <div class="col-xs-12">
            <div class="input-group input-group-sm">
              <div class="input-group-addon">
                {{ $t("contract") }}
              </div>
              <v-select
                class="contract-selector"
                v-on:click.stop.prevent="
                  () => {
                    false;
                  }
                "
                v-if="contractId"
                v-model="contractId"
                :options="contract_list"
                :reduce="({ id }) => id"
                label="name"
              >
                <template slot="option" slot-scope="option">
                  <span class="contract-selector-option">
                    {{ option.name || $t("contract") }} #{{ option.id }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t("no_process_area_available") }}
                </template>
              </v-select>
              <input
                v-else
                class="form-control"
                disabled
                v-bind:value="$t('loading')"
              />
            </div>
          </div>
        </div>
      </li>
      <li class="user-footer">
        <div class="row">
          <div class="col-xs-4 text-left">
            <router-link
              to="/dashboard/settings/profile"
              class="btn btn-default btn-block btn-flat"
              v-if="showProfile"
            >
              <span>
                <Icon name="fa fa-user" />
                {{ $t("profile") }}
              </span>
            </router-link>
          </div>
          <div class="col-xs-4 text-center">
            <router-link
              to="/dashboard/settings/billing"
              data-testid="my-plan"
              class="btn btn-default btn-block btn-flat"
              v-if="showMyPlan"
            >
              <span>
                <Icon name="fa fa-credit-card" />
                {{ $t("plan") }}
              </span>
            </router-link>
          </div>
          <div class="col-xs-4 text-right">
            <a
              href="javascript:void(0);"
              v-on:click="logout"
              data-testid="logout"
              class="btn btn-default btn-block btn-flat"
            >
              <Icon name="fa fa-sign-out" />
              {{ $t("sign_out") }}
            </a>
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import ContractAlertIcon from "@/components/contract-alert-icon.vue";
import Icon from "@/components/icons/icon.vue";

export default {
  name: "ProfileDropDownMenu",
  components: {
    ContractAlertIcon,
    Icon
  },
  data: function() {
    return {
      contract_id: "",
      reload: false
    };
  },
  computed: {
    contractId: {
      set(value) {
        if (value != "" && parseInt(this.contract_id) != parseInt(value)) {
          this.$utils.confirm(this, this.$t("you_have_unsaved_changes")).then((ok) => {
            if (!ok) {
              let bkp = this.contract_id;
              this.contract_id = "";
              this.$nextTick(() => {
                this.contract_id = bkp;
              })
              return
            };
            this.contract_id = value;
          });
        }
      },
      get() {
        return this.contract_id;
      }
    },
    can() {
      return (this.isLogged && !this.user.is_public_access) || false;
    },
    isAccountLocked() {
      return this.$store.getters["user/isAccountLocked"];
    },
    showProfile() {
      return !this.isAccountLocked;
    },
    showMyPlan() {
      return (
        this.$root.config &&
        this.$root.config.plan_upgrade &&
        (this.isRoot || (this.isDemo && !this.doesUserOwnAContract))
      );
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    doesUserOwnAContract() {
      // returns true if user own any contract
      let user_id = (this.user && this.user.id) || "";
      if (user_id) {
        let contract_list = this.contract_list;
        for (var i in contract_list) {
          if (contract_list[i].owner_id == user_id) {
            return true;
          }
        }
      }
      return false;
    },
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    contract_list() {
      return (this.user && this.user.is_member_contracts) || [];
    },
    busy() {
      return (
        !this.isReady ||
        (this.user &&
          this.contract_id != "" &&
          this.user.contract_id != this.contract_id) ||
        false
      );
    },
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    isLogged: function() {
      return this.user != null;
    },
    userEmail: function() {
      return this.user.email || "email?";
    },
    userFullName: function() {
      let ret =
        (this.user.first_name || "") + " " + (this.user.last_name || "");
      return ret;
    },
    userPicture: function() {
      let ret =
        (this.$store.getters["user/loggedUser"] &&
          this.$store.getters["user/loggedUser"].picture) ||
        "/static/common/images/nopicture.gif";
      return ret;
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/hitecnologia_logo.png"
      );
    },
    img_logo: function() {
      return (
        this.$store.getters["dashboard/property"]("company_logo") ||
        "/static/common/images/hitecnologia_mascot_70.png"
      );
    },
    title: function() {
      return (
        this.$store.getters["dashboard/property"]("company_name") ||
        "<b>HI</b> Tecnologia"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://hitecnologia.com.br"
      );
    }
  },
  watch: {
    user: {
      handler(n, o) {
        if (this.contract_id == "") {
          this.contract_id = n.contract_id;
        }
      },
      deep: true
    },
    contract_id(n, o) {
      if (n != "" && o != "" && parseInt(o) != parseInt(n)) {
        this.$store.dispatch("user/clearContractDependencies");
        this.$store.dispatch("user/updateActiveContract", n).then(() => {
          if (this.$route.path != "/dashboard/search") {
            this.reload = true;
            this.$router.push("/dashboard/search");
          }

        }).catch(() => {
          // It has been probably updated while in another tab
          this.reload = true;
          this.$router.push("/dashboard/search");
        });
      }
    },
    $route(to) {
      if (!this.reload) return;
      this.reload = false;
      document.location.reload();
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("user/logout");
      this.$store.dispatch("reset");
      this.$router.push("/");
    }
  },
  created() {
    if (this.user && this.user.contract_id) {
      this.contract_id = this.user.contract_id;
    }
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.user-footer .col-xs-4 {
  padding: 10px;
}
.nowrap {
  white-space: nowrap;
}

.skin-black-light .main-header li.user-header {
  background-color: #dbe0ea !important;
}

.navbar-nav > .user-menu > .dropdown-menu {
  box-shadow: 2px 4px 6px rgb(195, 195, 195);
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  min-height: 100px;
  height: auto;
  color: black;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  color: inherit;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  padding: 5px 15px;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #dddddd;
}

.select-contract {
  width: auto;
  max-width: 100px;
  padding: 2px 4px;
  border: 0;
  background-color: white;
  font-size: 0.8em;
}

.plan_access {
  background-color: whitesmoke;
}
.contract-alert-icon {
  float: left;
  margin: 0 10px 0 0;
}
.contract-selector-option {
  font-size: 90%;
}
>>> {
  /* custom */
  --vs-dropdown-option-padding: 3px 10px 5px 10px;
  --vs-line-height: 1.2rem;
}
</style>
<style>
.contract-selector .vs__dropdown-toggle .vs__selected {
  font-size: 80%;
  padding: 0;
  line-height: 1em;
  white-space: nowrap;
  max-width: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
