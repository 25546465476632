var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editor",staticClass:"dashboard-inner no-select",class:{ resizing: _vm.resizing },on:{"drop":function($event){return _vm.handleDrop($event)}}},[_vm._l((_vm.grid),function(row,ixRow){return _c('div',{key:ixRow,staticClass:"row",class:_vm.targetRow == ixRow && _vm.dragging == 'layout' ? `dragging` : '',on:{"dragover":function($event){_vm.targetRow = ixRow},"dragleave":function($event){_vm.targetRow = ''}}},[_c('div',{staticClass:"drop-zone"}),_vm._l((row),function(col,ixCol){return _c('div',{key:ixCol,staticClass:"col-xs-12",class:col.column_class,on:{"drop":function($event){return _vm.handleDrop($event, ixRow, ixCol)}}},_vm._l((col.panels),function(panelName,ixPanel){return _c('div',{key:ixPanel,staticClass:"painel-container",class:{
          disabled:
            (!_vm.currentDraftPanel || _vm.currentDraftPanel.name != panelName) &&
            !_vm.resizing,
          dragging: _vm.targetPanel == panelName && _vm.dragging == 'panel'
        },on:{"drop":function($event){return _vm.handleDrop($event, ixRow, ixCol, ixPanel)},"click":function($event){$event.preventDefault();return _vm.onPanelContentClicked(panelName)},"dragover":function($event){_vm.targetPanel = panelName},"dragleave":function($event){_vm.targetPanel = ''}}},[(
            (!_vm.currentDraftPanel || _vm.currentDraftPanel.name != panelName) &&
            !_vm.resizing
          )?_c('div',{staticClass:"panel-handler",attrs:{"draggable":"true","title":`${_vm.$t('click_to_edit')} ${_vm.$t('or')} ${_vm.$t('drag_to_move')}`},on:{"dragstart":function($event){return _vm.jsonDragger.onDragStart($event, 'panelsSwap', panelName)},"dragend":function($event){return _vm.jsonDragger.onDragEnd($event)},"dragover":function($event){return _vm.jsonDragger.onDragOver($event)}}},[(_vm.panelInfo[panelName])?_c('div',{staticClass:"container-info"},[_c('div',{staticClass:"text-center"},[_c('div',[_c('img',{staticStyle:{"width":"80px","margin-top":"10px"},attrs:{"src":_vm.panelInfo[panelName].thumbnail}})]),_c('div',{staticStyle:{"font-size":"1.6em"}},[_vm._v(" "+_vm._s(_vm.panelInfo[panelName].title)+" ")])])]):_vm._e(),_c('div',{staticClass:"drag-info"},[_c('div',[_c('div',[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" "+_vm._s(_vm.$t("click_to_edit"))+" ")]),_c('div',[_c('i',{staticClass:"fa fa-arrows-alt"}),_vm._v(" "+_vm._s(_vm.$t("drag_to_move"))+" ")])])])]):_vm._e(),_c('PanelEditorToolbar',{attrs:{"panelName":panelName},on:{"click":_vm.onPanelToolbar}}),_c('EquipmentDashboardPanel',{attrs:{"equipment":_vm.equipment,"display":_vm.display,"name":panelName,"screenId":_vm.screenId},on:{"mounted":_vm.panelMounted}}),(ixCol < row.length - 1)?_c('div',{staticClass:"panel-resize-handler",class:{ resizing: _vm.resizing },style:({
            right:
              _vm.resizing && _vm.resizing.r == ixRow && _vm.resizing.c == ixCol
                ? _vm.resizing.pos
                : '0'
          }),on:{"mousedown":function($event){return _vm.handleMousedown($event, ixRow, ixCol)}}},[_vm._m(0,true)]):_vm._e()],1)}),0)}),(!_vm.resizing)?_c('div',{staticClass:"layout-toolbar",attrs:{"title":_vm.$t('remove_layout')},on:{"drop":function($event){return _vm.handleDrop($event, ixRow)}}},[_c('div',[(ixRow > 0)?_c('span',{staticClass:"btn btn-xs",class:_vm.isPageBrakeEnabledAt(ixRow) ? 'btn-active' : '',staticStyle:{"margin-right":"15px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.enablePageBrakeAt(ixRow)}}},[_vm._v(" "+_vm._s(_vm.$t("page_brake"))+" "),_c('i',{staticClass:"fa fa-eject"})]):_vm._e(),(_vm.canAddColumn(ixRow))?_c('span',{staticClass:"btn btn-xs",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addColumn(ixRow)}}},[_vm._v(" "+_vm._s(_vm.$t("rich_text.add_column"))+" "),_c('i',{staticClass:"fa fa-columns"})]):_vm._e(),_c('span',{staticClass:"btn btn-xs",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeRow($event, ixRow)}}},[_vm._v(" "+_vm._s(_vm.$t("remove_layout"))+" "),_c('i',{staticClass:"fa fa-trash"})])]),(!_vm.resizing && ixRow > 0 && _vm.isPageBrakeEnabledAt(ixRow))?_c('section',{staticClass:"page-break-indicator",attrs:{"title":_vm.$t('page_brake')}},[_c('i',{staticClass:"fa fa-eject"})]):_vm._e()]):_vm._e()],2)}),_c('div',{staticClass:"row",class:_vm.targetRow == -1 && _vm.dragging == 'layout' ? `dragging` : '',on:{"dragover":function($event){_vm.targetRow = -1},"dragleave":function($event){_vm.targetRow = ''}}},[_c('div',{staticClass:"drop-zone"}),_c('div',{staticClass:"col-xs-12",style:(_vm.dragging == 'layout' ? 'height: 100dvh' : '')})])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div')])
}]

export { render, staticRenderFns }